@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 0%;
    --foreground: 210 40% 98%;

    --card: 0 0% 6%;
    --card-foreground: 210 40% 98%;

    --popover: 210 40% 98%;
    --popover-foreground: 0 0% 0%;

    --primary: 1.27 100% 46.47%;
    --primary-foreground: 0 0% 100%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 0 0% 6%;
    --muted-foreground: 0 0% 50%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 212.7 26.8% 83.9%;
    --radius: 0.25rem;
  }
}

@layer utilities {
  .base-input {
    @apply h-10 rounded-md border border-input px-3 py-2 text-sm text-[#30313D] ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;

    .grecaptcha-badge {
      @apply !hidden;
    }
  }
}

.swiper {
  width: 100%;
  max-width: 400px;
  /* height: 380px; */
}

@media (min-width: 768px) {
  .swiper {
    /* height: 500px; */
    max-width: 600px;
  }
}

.swiper-slide {
  border-radius: 24px;
  background: #222023;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #2b282c;
}

.swiper-pagination {
  bottom: -30px !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
}

.swiper-button-next {
  color: #fff !important;
  top: 65% !important;
  margin-right: -40px;
}
.swiper-button-prev {
  color: #fff !important;
  top: 65% !important;
  margin-left: -40px;
}

@media (max-width: 1024px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.fancy-gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
